$primary-color: #2b2e83;
$selected: #0e71b8;

$bg_color: #f7f7f7;

$white_1: rgba(255, 255, 255, 0.301);

$green_1: #96c11f;

$grey_1: #4b4b4b;
$grey_2: #00000029;
$grey_3: #848484;
$yellow_1: #fdc300;
$green_2: #009a00;
$grey_4: #cccccc;
$grey_5: #acacac;
$grey_6: #d9d9d9;
$grey_7: #d6d7e4;

$red_1: #ff5f57;
