@import "color.scss";

.ant-table-tbody {
  padding: 0 80px;
  background: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 23px;

  > tr {
    cursor: pointer;
  }
  .ant-table-row {
    position: relative;
    &:hover {
      td {
        &:first-child {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-left: 4px solid $primary-color;
          }
        }
      }
    }
  }
}

.ant-modal-content {
  border-radius: 5px !important;
}
button.ant-btn.ant-btn-primary.ant-btn-lg {
  height: 60px;
  border-radius: 4px;
  padding: 6.4px 30px;
}

button.ant-btn.ant-btn-primary.ant-btn-lg.ant-btn-background-ghost.btn-ghosh-clear {
  color: $primary-color;
  border-color: $primary-color;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
  margin-bottom: 25px;
}

button.ant-btn.ant-btn-primary.ant-btn-background-ghost.btn-ghosh-blue {
  color: $selected;
  border-color: $selected;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
}

button.ant-btn.ant-btn-primary.ant-btn-background-ghost.btn-ghosh-green {
  color: $green_1;
  border-color: $green_1;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
}

button.ant-btn.ant-btn-primary.ant-btn-background-ghost.btn-ghosh-primary {
  color: $primary-color;
  border-color: $primary-color;
  border-radius: 4px;
  font-weight: bold;

  font-size: 18px;
  padding: 6.4px 70px;
  height: 60px;
}
button.ant-btn.ant-btn-primary.btn-green-primary {
  background-color: $green_1;
  border-color: $green_1;
  height: 60px;
  padding: 6.4px 60px;
  font-size: 22px;
  border-radius: 4px;
}

button.ant-btn.ant-btn-primary.ant-btn-lg.btn-custom-filter {
  border-color: $grey_3;
  background: $grey_3;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
  width: 100%;
  margin-bottom: 25px;
}
button.ant-btn-dangerous.ant-btn-primary {
  background-color: #5cdbd3;
  border-color: #5cdbd3;
}

button.ant-btn.ant-btn-primary.btn-edit {
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
  // border-color: $grey_1;
}
button.ant-btn.ant-btn-primary.btn-blue {
  border-color: $selected;
  background: $selected;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
}
button.ant-btn.ant-btn-primary.btn-green-w70 {
  border-color: $green_1;
  background: $green_1;
  border-radius: 4px;
  font-size: 22px;
  padding: 6.4px 70px;
  height: 60px;
}

button.ant-btn.ant-btn-primary.btn-green {
  border-color: $green_1;
  background: $green_1;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
}

button.ant-btn.ant-btn-primary.ant-btn-lg.btn-custom-home {
  border-color: $selected;
  background: $selected;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
}
button.ant-btn.ant-btn-primary.ant-btn-lg.btn-custom-cancle {
  border-color: $red_1;
  background: $red_1;
}

button.ant-btn.ant-btn-primary.ant-btn-lg.btn-custom-m-search {
  border-color: $green_1;
  background: $green_1;
  height: 46px;
  margin-bottom: -10px;
  width: 100%;
}
button.ant-btn.ant-btn-primary.ant-btn-lg.ant-btn-background-ghost.btn-ghosh-m-clear {
  height: 46px;
  margin-bottom: -10px;
  width: 100%;
}

button.ant-btn.ant-btn-primary.ant-btn-lg.btn-custom-search {
  border-color: $green_1;
  background: $green_1;
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 60px;
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

button.ant-btn.ant-btn-primary.ant-btn-background-ghost.btn-cancle-blue {
  color: $selected;
  border-color: $selected;
  border-radius: 4px;
  padding: 6.4px 30px;
  height: 60px;
}

button.ant-btn.ant-dropdown-trigger.dd-bt {
  background-color: $selected;
  color: white;
  border-radius: 4px;
  padding: 6.4px 30px;
  height: 60px;
}
button.ant-btn.ant-dropdown-trigger.dd-bt:hover,
button.ant-btn.ant-dropdown-trigger.dd-bt:focus {
  border-color: #d9d9d9;
}

button.ant-btn.ant-dropdown-trigger.dropdown-cancel {
  background-color: gray;
  color: white;
  border-radius: 4px;
  padding: 6.4px 30px;
  height: 60px;
}

.bg-gray-hover:hover {
  background-color: #6b5b5b !important;
}

.bg-blue-hover:hover {
  background-color: #276091 !important;
}

button.ant-btn.ant-btn-primary.ant-btn-background-ghost.btn-add-data {
  color: $selected;
  border-radius: 5px;
  border-color: $selected;
  height: 45px;
  margin-bottom: 15px;
  padding: 5px 43px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-dd {
  background-color: $selected;
  box-shadow: none;
  width: 100%;
  // border: 1px solid transparent;
  border-top: none;
  border-left: 1px solid $grey_6;
  border-right: 1px solid $grey_6;
  border-bottom: 1px solid $grey_6;

  border-radius: 0 0 4px 4px;
  margin-top: -8px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $primary-color;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-close {
  //font-size: 18px;
  text-indent: 0em;
  height: 35px;
  color: rgb(255, 255, 255);
  background-color: $red_1;
  transition: all 0.1s;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-close:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-list-close {
  background-color: $red_1;
  margin-top: -7px;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-new {
  //font-size: 18px;
  text-indent: 0em;
  height: 35px;
  color: rgb(255, 255, 255);
  background-color: $selected;
  transition: all 0.1s;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-partial {
  font-size: 16px;
  text-indent: 0em;
  height: 35px;
  color: rgb(0, 0, 0);
  background-color: $yellow_1;
  transition: all 0.1s;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-done {
  font-size: 16px;
  text-indent: 0em;
  height: 35px;
  color: rgb(255, 255, 255);
  background-color: $green_2;
  transition: all 0.1s;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-user-item {
  //font-size: 20px;
  // text-indent: 0em;
  height: 60px;
  color: rgb(0, 0, 0);
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-df-created {
  //font-size: 18px;
  text-indent: 0em;
  height: 35px;
  color: rgb(255, 255, 255);
  background-color: #96c11f;
  transition: all 0.1s;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-df-created:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-user-item:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-done:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-partial:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.menu-item-new:hover {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-list-done {
  background-color: $green_2;
  margin-top: -7px;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-list-new {
  background-color: $selected;
  margin-top: -7px;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-list-partial {
  background-color: $yellow_1;
  margin-top: -7px;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-user {
  background-color: $grey_7;
  box-shadow: none;
  width: 100%;
  border-top: none;
  border-left: 1px solid $grey_7;
  border-right: 1px solid $grey_7;
  border-bottom: 1px solid $grey_7;
  border-radius: 0 0 4px 4px;
  margin-top: -8px;
}

ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.menu-df-created {
  background-color: #96c11f;
  margin-top: -7px;
  box-shadow: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

button.ant-btn.ant-dropdown-trigger.btn-user {
  background-color: $grey_7;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  //font-size: 20px;
  padding: 6.4px 30px;
  height: 80px;
  border: 1px solid $grey_7;
}

li.ant-dropdown-menu-item {
  text-indent: 2em;
  height: 51px;
  color: white;
}

.ant-input.input-search {
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 46px;
}

.ant-input-affix-wrapper.input-search {
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  > .ant-input {
    font-size: 16px;
  }
}

.ant-input.input-center {
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  height: 46px;
  min-width: 75px;
}

.ant-input.input-end {
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  text-align: end;
}

.ant-select.input-search {
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
}
.ant-select-single .ant-select-selector {
  height: 46px !important;
  padding-top: 5px !important;
  width: 100%;
  border-radius: 5px !important;
}

.ant-select.ant-select-lg.select-custom.ant-select-single.ant-select-show-arrow {
  top: -5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}
.ant-select-item.item-select {
  //font-size: 20px;
  width: 100%;
}

.ant-table {
  font-size: 16px !important;
  font-weight: lighter;
}

.ant-table-thead > tr > th {
  background: $primary-color !important;
  color: white !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  padding-left: 60px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding-left: 60px;
}

tr.ant-table-row.ant-table-row-level-0 td:first-child {
  padding-left: 60px !important;
}
.ant-table-wrapper.list-data {
  tr.ant-table-row.ant-table-row-level-0 td:first-child {
    padding-left: 0px !important;
  }
  .ant-table table {
    tbody.ant-table-tbody {
      box-shadow: none;
      > tr {
        cursor: default;
      }
      .ant-table-row {
        position: relative;
        &:hover {
          td {
            &:first-child {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-left: none;
              }
            }
          }
        }
      }
    }
  }
}

button.ant-btn.ant-btn-primary.btn-waiting {
  background-color: $yellow_1;
  color: black;
  border: none;
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 5px;
  min-width: 160px;
}

button.ant-btn.ant-btn-primary.btn-done {
  background-color: $green_2;
  border: none;
  width: 140px;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  min-width: 160px;
}
button.ant-btn.ant-dropdown-trigger.btn-cancel {
  background-color: $red_1;
  border: none;
  color: white;
  font-size: 16px;
  width: 140px;
  height: 40px;
  border-radius: 5px;
  min-width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button.ant-btn.ant-dropdown-trigger.btn-openbill {
  background-color: $selected;
  border: none;
  font-size: 16px;
  min-width: 170px;
  color: white;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.ant-btn.ant-dropdown-trigger.btn-closebill {
  background-color: $green_2;
  border: none;
  font-size: 16px;
  min-width: 170px;
  color: white;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.ant-btn.ant-dropdown-trigger.btn-partial {
  background-color: $yellow_1;
  border: none;
  font-size: 16px;
  min-width: 170px;
  color: rgb(0, 0, 0);
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.ant-btn.ant-dropdown-trigger.btn-df-created {
  background-color: #96c11f;
  border: none;
  font-size: 16px;
  min-width: 170px;
  color: white;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

td.ant-table-cell {
  color: $grey_1;
}

.ant-row.custom-row {
  height: 55px;
}

input.ant-input.input-medium {
  height: 40px;
  //font-size: 18px;
  border-radius: 5px;
  width: 135px;
  margin-bottom: 10px;
  text-align: end;
  display: flex;
  justify-content: flex-end;
}

.ant-input-number.ant-input-number-lg.input-w100 {
  height: 46px;
  //font-size: 18px;
  border-radius: 5px;
  width: 100%;
  // margin-bottom: 10px;
  text-align: start;
}

.ant-row.ant-form-item {
  justify-content: flex-end;
}
.ant-picker.date-picker-custom {
  height: 45px;
  width: 100%;
  //font-size: 18px;
  > .ant-picker-input > input {
    font-size: 16px;
  }
  border-radius: 5px;
}
.month-cs button.ant-picker-header-super-next-btn {
  display: none;
}
.month-cs button.ant-picker-header-super-prev-btn {
  display: none;
}

.ant-picker.date-picker-search-custom {
  height: 50.8px;
  width: 100%;
  //font-size: 18px;
  // justify-content: flex-end;
  > .ant-picker-input {
    width: 38%;
    margin-left: 25px;
    margin-right: 25px;

    > input {
      width: 50%;
      font-size: 16px;
      background-color: $grey_6;
      color: $primary-color;
      text-align: center;
    }
  }
  border-radius: 5px;
}

.ant-picker.date-picker-custom-center {
  height: 45px;
  width: 100%;
  //font-size: 18px;
  min-width: 95px;
  > .ant-picker-input > input {
    font-size: 16px;
  }
  border-radius: 5px;
  input {
    text-align: center;
  }
}

.ant-input-number.ant-input-number-lg.number-cutom {
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
}

.ant-form-item-label > label {
  font-size: 20px !important;
}

.ant-radio {
  top: 6px !important;
}
.ant-radio-inner {
  width: 25px !important;
  height: 25px !important;
}
.ant-radio-inner::after {
  width: 17px !important;
  height: 17px !important;
}

.ant-form label {
  font-size: 18px !important;
  color: $grey_1;
}
span.ant-input-affix-wrapper.input-line {
  border: none;
  border-bottom: 2px solid #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 11px;
  span.ant-input-prefix {
    margin-right: 15px;
  }
  .ant-input {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 18px;
    color: #ffff;
  }
  span.ant-input-suffix {
    span.anticon.anticon-eye-invisible.ant-input-password-icon {
      svg {
        fill: #ffff;
      }
    }
    span.anticon.anticon-eye.ant-input-password-icon {
      svg {
        fill: #ffff;
      }
    }
  }
}

label.ant-checkbox-wrapper.checkbox-cutom {
  color: #ffff;
}

button.ant-btn.ant-btn-primary.ant-btn-block.btn-block {
  background-color: $selected;
  height: 60px;
  font-size: 16px;
}

.ant-input-number.ant-input-number-lg.input-medium {
  height: 45px;
  border-radius: 5px;
  width: 135px;
  font-size: 16px;
  margin-bottom: 10px;
  // text-align: end;
  .ant-input-number-input {
    text-align: end;
    padding-top: 8px;
  }
}
.ant-select.ant-select-lg.select-custom.ant-select-single.ant-select-allow-clear.ant-select-disabled {
  font-size: 16px;
}
.ant-input-number.ant-input-number-lg.ant-input-number-readonly.input-medium.ant-input-number-disabled.ant-input-number-readonly {
  font-size: 16px;
}
.ant-input-number.ant-input-number-lg.input-end {
  height: 45px;
  border-radius: 5px;
  min-width: 135px;
  font-size: 16px;
  // margin-bottom: 10px;
  // text-align: end;
  .ant-input-number-input {
    text-align: end;
    height: 45px;
  }
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

button.ant-btn.ant-btn-primary.ant-btn-icon-only.btn-edit-table {
  border: $primary-color;
  background-color: $primary-color;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  img {
    margin-top: -10px;
  }
}
button.ant-btn.ant-btn-primary.ant-btn-icon-only.btn-delete-table {
  border: $red_1;
  background-color: $red_1;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  img {
    margin-top: -10px;
  }
}
button.ant-btn.ant-btn-primary.ant-btn-icon-only.btn-delete-table-disable {
  border: $red_1;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  background-color: #ff5f57b9;
  cursor: no-drop;
  img {
    margin-top: -10px;
  }
}
// button.ant-btn.ant-btn-primary.ant-btn-icon-only
ul.ant-pagination.pgt-custom {
  text-align: end;
  margin-top: 25px;
  .ant-pagination-item {
    min-width: 46px;
    min-height: 46px;
    a {
      height: 100%;
      display: flex;
      padding: 0 8px;
      color: rgba(0, 0, 0, 0.85);
      transition: none;
      justify-content: center;
      align-items: center;
      font-size: 17px;
    }
  }
  .ant-pagination-item-active {
    background-color: $primary-color;
    a {
      color: white;
    }
  }
  li.ant-pagination-next,
  li.ant-pagination-prev {
    height: 46px;
    width: 46px;
  }
  span.ant-select-selection-item {
    line-height: 35px;
  }
  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    font-size: 17px;
  }
}

.ant-select-item.ant-select-item-option {
  text-align: start;
}

button.ant-btn.ant-btn-link.ant-btn-lg.ant-btn-block.btn-link-upload {
  //font-size: 18px;
  color: $selected;
}

.form-color-primary .ant-form-item-label > label {
  color: $primary-color;
}

.ant-btn-background-ghost.ant-btn-primary.refresh-btn {
  min-width: 100px;
  max-width: 100px;
  display: block;
  width: 100%;
  border-radius: 5px;
  height: 46px;
  margin-top: 12px;

  span {
    // padding-left: 8px;
    font-size: 16px;
  }
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.f-btn {
  /* background-color: red; */
  height: 60px;
  width: 160px;
  font-size: 18px;
  text-align: center;
  padding-top: 9px;
  border-radius: 5px;
}

label.ant-radio-button-wrapper.f-btn {
  height: 60px;
  width: 160px;
  font-size: 18px;
  text-align: center;
  padding-top: 9px;
  border-radius: 5px;
  color: $grey_3;
}
.ant-input-number-group-addon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 46px !important;
}

.ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
}

.ant-table-wrapper.grey-table {
  .ant-table-thead > tr > th {
    color: white;
    background: $grey_1 !important;
  }
}
button.ant-btn.ant-btn-primary.btn-add-list {
  margin-top: 25px;
  background-color: $green_1;
  height: 60px;
  border: none;
}

.ant-input-number.input-number-custom {
  // height: 44px;
  border-radius: 5px;
  width: 100%;
  //font-size: 18px;
  padding-top: 4px;
}

.bar-chart .ant-progress-outer .ant-progress-inner {
  height: 55px !important;
  border-radius: 5px !important;
}
.bar-chart .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  height: 55px !important;
  border-radius: 0 5px 5px 0 !important;
}

span.ant-tag.ant-tag-success.tag-custom {
  //font-size: 18px;
  padding: 5px;
  min-width: 90px;
}

span.ant-tag.ant-tag-error.tag-custom {
  //font-size: 18px;
  padding: 5px;
  min-width: 90px;
}

.ant-notification {
  font-size: 18px !important;
}

.ant-select-selection-overflow {
  min-height: 42px;
}

.ant-statistic-title {
  margin-bottom: -16px !important;
}

.ant-card.ant-card-bordered.custom-statistic {
  border-radius: 5px;
  border: 1px solid black;
  .ant-card-body {
    padding: 5px 15px 5px 15px;
  }
}

button.ant-btn.ant-btn-primary.custom-btn-submit {
  height: 44px;
  // width: 100px;
  border-radius: 5px;
}

button.ant-btn.ant-btn-primary.ant-btn-block.btn-block-white {
  background-color: white;
  color: black;
  height: 60px;
  font-size: 24px;
  border-radius: 5px;
  margin-top: 35px;
}

.ant-col.text-title-login-mobile {
  h2 {
    color: white;
    margin-bottom: 0;
  }
  p {
    color: white;
    font-size: 14px;
    margin-bottom: 60px;
  }
}

button.ant-btn.ant-btn-primary.ant-btn-block.btn-download {
  background-color: $selected;
  border: 1px solid $selected;
  border: none;
  //font-size: 18px;

  border-radius: 5px 0px 0px 5px;
}
button.ant-btn.ant-btn-primary.ant-btn-block.btn-goto {
  background-color: white;
  border: 1px solid $grey_5;
  //font-size: 18px;
  color: #000;
  border-radius: 0px 5px 5px 0px;
}
button.ant-btn.ant-btn-default {
  background-color: $green_1;
  color: white;
  border-color: $green_1;
  height: 40px;
  font-size: 16px;
}

.ant-pagination-options-quick-jumper {
  font-size: 18px !important;
  > input {
    height: 46px !important;
    border-radius: 5px !important;
    font-size: 18px !important;
    width: 75px !important;
  }
}

.ant-table-wrapper.not-hover {
  .ant-table table {
    tbody.ant-table-tbody {
      box-shadow: none;
      > tr {
        cursor: default;
      }
      .ant-table-row {
        position: relative;
        &:hover {
          td {
            &:first-child {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-left: 4px solid $primary-color;
              }
            }
          }
        }
      }
    }
  }
}
.ant-table-wrapper.abc {
  .ant-table table {
    border: 1px solid #2b2e83;
  }
}
.example-fontsize-18 {
  font-size: 18px !important;
}
.modal-title-20 {
  margin-bottom: 0px;
}

.ant-modal-footer {
  border-top: 0px !important;
}
.label-margitop-10 {
  margin-top: 10px;
}
.modal-math-text-red {
  color: red;
}

span.ant-tag.tag-year {
  background: white;
  min-width: 70px;
  height: 35px;
  font-size: 22px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}

.ant-input-number.ant-input-number-lg.ant-input-number-readonly.input-medium.ant-input-number-readonly {
  font-size: 16px;
}

button.ant-btn.ant-btn-primary.btn-custom-blue {
  height: 60px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0e71b8 !important;
  color: white !important;
}
.ant-menu {
  color: #2b2e83 !important;
}
.ant-popover-inner-content {
  width: 280px;
}

.border-bottom-only {
  border-bottom: 1px solid #555555 !important;
  border-radius: 0px !important;
}
