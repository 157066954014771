.logo-center {
  display: flex;
  padding-top: 16px;
  padding-left: 20px;
  background-color: rgb(255, 255, 255);
}
.meter-bg {
  background-color: #fff;
  height: 100%;
}
.logo-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #fff;
}
.btn-mobile {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
button.ant-btn.ant-btn-primary.btn-style {
  background-color: $primary-color;
  border-radius: 10px;
  height: 80px;
  width: 80%;
  //font-size: 20px;
}
.button.icon {
  position: absolute;
  margin-top: 50px;
}
.bg-scan {
  background-color: $primary-color;
}
h2 {
  //font-size: 20px;
}
button.ant-btn.ant-btn-primary.btn-cancle {
  margin-left: 10px;
  background-color: #fff;
  color: #0e71b8;
  border-color: #0e71b8;
}
button.ant-btn.ant-btn-primary.btn-submit {
  margin-left: 10px;
  background-color: #0e71b8;
  border-color: #0e71b8;
}
.btn-mobile-write {
  display: flex;
  justify-content: right;
}
button.ant-btn.ant-btn-primary.ant-btn-icon-only.btn-back {
  border-color: none;
  background-color: none;
}
span.anticon.anticon-arrow-left {
  margin-top: 6px;
  margin-bottom: 14px;
}
span.anticon.anticon-arrow-left svg {
  font-size: 24px;
}
.ant-input-number.ant-input-number-lg.input-search-mobile {
  width: 100%;
}
.ant-input-number.input-search-mobile {
  width: 100%;
}
.icon-list svg {
  margin-left: 20px;
  margin-top: 15px;
}
.margintop-15 {
  margin-top: 15px;
  margin: 15px;
}
.bg-qr-code {
  background-color: #2b2e83;
  height: 100vh;
}
// .qr-code-scaner{
//     section{
//         border-radius: 15px;
//     }
// }
.bg-qr-reader {
  margin-top: 30px;
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px;
  // border-radius: 15px;
}
.bg-h2 {
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  height: 50px;
}
.logo-scanQrcode {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 10px;
}
.scan-icon svg {
  color: #fff;
  margin-top: 20px;
  margin-left: 20px;
}
.color-white-h1 {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: 10px;
}
h6.color-white-h6 {
  display: flex;
  justify-content: center;
  color: #fff;
}
.scan-margintop-10 {
  margin-top: 30px;
}
