/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.icon-menu {
  padding-right: 12px;
}

.ant-col.title-header p {
  //font-size: 20px;
  font-weight: bold;
  font-family: "Kanit";
  margin-bottom: 5px;
  color: $primary-color;
}
.ant-col.title-header h1 {
  font-family: "Kanit";
  color: $primary-color;
  font-weight: bold;
}

.body-page {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}

.body-page-edit-password {
  width: 50%;
  margin-top: 40px;
}

.icon-btn {
  padding-right: 25px;
}

.hr-line {
  margin-top: -7px;
}

.table-data {
  overflow: auto;
}
.table-data-border {
  overflow: auto;
  border: 1px solid $primary-color;
  margin-bottom: 15px;
}
.body-list {
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.body-pdf {
  padding: 0px 50px;
}
table.table-list-custom {
  table-layout: auto;
  width: 100%;
  margin-bottom: 25px;
  //font-size: 20px;
  th {
    border: 1px solid $grey_1;
    color: $primary-color;
    padding: 10px;
    padding-left: 25px;
    font-size: 16px;
  }
  td {
    border-left: 1px solid $grey_1;
    border-right: 1px solid $grey_1;
    color: $grey_1;
    padding: 10px;
    padding-left: 25px;
  }
  tr:nth-child(even) {
    background-color: $bg_color;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
}

table.table-cutom {
  border: 1px solid $grey_1;
  border-collapse: collapse;
  //font-size: 20px;
  padding: 10px;
  color: $grey_1;
  margin-bottom: 25px;
}
.head-table {
  color: $primary-color;
  font-size: 25px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 150px;
  border: 1px solid $grey_1;
  p {
    margin: 0;
    font-size: 25px;
  }
}

.title-table-h {
  table-layout: fixed;
  width: 180px;
  color: $primary-color;
  font-size: 16px;
  border-bottom: 1px solid $grey_1;
  border-right: 1px solid $grey_1;
  padding: 10px;
}
.title-table-p {
  table-layout: fixed;
  width: 180px;
  font-size: 16px;
  border-bottom: 1px solid $grey_1;
  border-right: 1px solid $grey_1;
  padding: 10px;
}
.title-table-f {
  border-right: 1px solid $grey_1;
  border-left: 1px solid $grey_1;
  padding: 10px;
  padding-left: 45px;
}

p.address-table {
  margin: 0;
  //font-size: 18px;
}

td.sum-title-td {
  background-color: white;
  text-align: end;
  padding-right: 25px;
  font-size: 16px;
  border: none !important;
  color: $primary-color !important;
}

.qr-code {
  padding: 5px;
  border: 1px solid $grey_1;
  // display: table;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

// .bank-detail {
//     padding-top: 12px;
// }
.bank-detail > p {
  //font-size: 18px;
  margin: 0;
}
.transfer {
  color: $primary-color;
  font-size: 22px !important;
}
.bank {
  color: $primary-color;
  font-size: 26px !important;
}
table.table-add {
  text-align: left;
  //font-size: 18px;
  color: #fff;
  width: 100%;
  // border: 1px solid $primary-color;
  tr:nth-child(even) {
    background-color: $bg_color;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }
  td {
    .ant-row.ant-form-item {
      margin: 0;
    }

    padding: 5px 30px 5px 30px;
  }
  tr.tr-header {
    background-color: $primary-color;
    //font-size: 18px;
  }
}

table.table-add-properties {
  text-align: left;
  font-size: 22px;
  color: #fff;
  width: 100%;
  background-color: #fff;

  td {
    .ant-row.ant-form-item {
      margin: 0;
    }

    padding: 5px 30px 5px 30px;
  }
  tr.tr-header {
    background-color: $primary-color;
  }
}

th.th-size {
  height: 60px;
  padding-left: 35px;
}

.table-edit-border {
  overflow: auto;
  border: 1px solid $primary-color;
  table.table-add td {
    padding: 5px 15px 5px 15px;
  }
}
.table-edit-border-w70 {
  overflow: auto;
  border: 1px solid $primary-color;
  width: 70%;
}
.table-edit-border-grey {
  overflow: auto;
  border: 1px solid $grey_4;
}
.sum-table {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  width: 100%;
  align-items: flex-end;
  // z-index: 21;
  margin-top: -60px;
  padding-right: 90px;
}

.sum-table-receipt {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  width: 100%;
  align-items: flex-end;
  // z-index: 21;
  margin-top: -60px;
  padding-right: 20px;
}

p.text-input {
  font-size: 16px;
  padding-right: 20px;
  margin-bottom: 8px;
  padding-top: 12px;
}

.login-page {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  // align-items: center;
  padding: 45px;
}
.ant-col.img-backdrop {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.ant-col.text-login {
  h1 {
    color: $primary-color;
    font-family: "Kanit";
    font-weight: bold;
  }
  p {
    color: $primary-color;
    font-family: "Kanit";
    font-size: 19px;
    margin: 0;
  }

  //
}

.form-login {
  // background-color: #555;
  padding: 10px;
  height: 100vh;
  .bg-page-login {
    background-color: $primary-color;
    height: 100%;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px;
  }
}

.tab-fogot {
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    color: #fff;
    cursor: pointer;
  }
}

.ant-col.text-title-login {
  h2 {
    color: white;
    font-family: "Kanit";
  }
  p {
    color: white;
    font-family: "Kanit";
    //font-size: 18px;
    margin-bottom: 60px;
  }
}
.border-body {
  border: 1px solid $grey_4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 35px;
}
.border-body-qr {
  border: 1px solid $grey_4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 35px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.border-body-icon {
  border: 1px solid $grey_4;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}
.title-info {
  background-color: $primary-color;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 15px 15px 30px;
  color: #fff;
  p {
    margin: 0;
    //font-size: 18px;
  }
}

table.table-list-custom td:not(:first-child) {
  text-align: right;
}

td.string-amount-td {
  background-color: white;
  text-align: end;
  border: 1px solid #4b4b4b;
  color: #2b2e83 !important;
}

.mt-4 {
  margin-top: 36px !important;
}

.body-admin {
  min-height: 100px;
  border-right: 1px solid $grey_3;
  padding: 10px;
}

.title-role {
  font-size: 22px;

  margin: 0;
}

.sub-title-role {
  font-size: 16px;
  color: $grey_3;
}

.cb-title {
  padding-left: 20px;
  color: $grey_3;
  padding-top: 20px;
}

.add-list-unit {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 25px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.red {
  color: $red_1;
}

// .swal2-styled.swal2-confirm {
//   font-size: 22px !important;
//   height: 60px;
//   width: 100px;
// }

// .box-report {
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   min-width: 100%;
//   height: 100%;
//   background-color: $primary-color;
// }

.square-box {
  width: 100%;
  background-color: $primary-color;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.square-box:before {
  content: "";
  display: block;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.square-content {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  border-radius: 5px;

  p.title {
    //font-size: 20px;
    text-align: start;
    margin-bottom: 30px;
  }
  p.number {
    font-size: 4vw;
    text-align: end;
    margin: 0;
  }
  p.unit {
    //font-size: 20px;
    margin-bottom: 30px;
    margin-top: -25px;
    text-align: end;
  }
  @media only screen and (min-width: 320px) {
    p.number {
      font-size: 55px;
      text-align: end;
      margin: 0;
    }
  }
}

.chart-data {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  border-radius: 5px;
  min-width: 50px;
  padding: 25px;
  min-height: 50px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.title-chart {
  //font-size: 18px;
  color: $primary-color;
}

.sub-chart {
  cursor: pointer;
  font-size: 16px;

  color: $primary-color;
  .dot {
    background-color: $primary-color;
  }
  p {
    margin: 0;
  }
  text-align: center;
}

.sub-chart-g {
  cursor: pointer;
  font-size: 16px;
  color: $green_1;
  p {
    margin: 0;
  }
  .dot {
    background-color: $green_1;
  }
  text-align: center;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.title-chart-bar {
  font-size: 16px;
  margin: 0;
}

.fine-cutom {
  color: $red_1;
}

.inline {
  display: flex;
}

.inline > * {
  display: flex;
}

.marginBottom-10 {
  margin-bottom: 10px;
}

.marginBottom-0 {
  margin-bottom: 0px;
}

.login-page-mobile {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: 45px;
}

.bg-page-login-mobile {
  background-color: $primary-color;
  padding: 45px 15px 25px 15px;
  border-radius: 5px;
  margin: 45px 15px 15px 15px;
}

.ant-col.text-login-mobile {
  margin-top: 25px;
  h1 {
    color: $primary-color;
    font-family: "Kanit";
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  p {
    color: $primary-color;
    font-family: "Kanit";
    font-size: 10px;
    margin: 0;
    text-align: center;
  }

  //
}

.qr-code-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    margin-top: -10px;
    z-index: 1;
    margin-top: -46px;
    margin-left: 49px;
  }
}

.center-dropdown {
  display: flex;
  justify-content: center;
}

.note-red {
  color: $red_1;
  margin: 0;
}
.ml-10 {
  margin-left: 10px;
}

.ml-35 {
  margin-left: 35px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fw-normal {
  font-weight: normal;
}
.body-refresh {
  margin-bottom: 15px;
}

.end {
  text-align: end;
}
.title-bank-table {
  color: #000;
  border: 1px solid #000;
  padding: 5px;
  margin: 0;
}

.text-center {
  text-align: center;
}

.box-ref {
  border: 1px solid black;
  padding: 10px;
  margin-right: 15px;
}
.check-box {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  border: 1px solid black;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}
.m-0 {
  margin: 0;
}
.pd-l-30 {
  padding-left: 30px;
}

.modal-body-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 18px;
  }
}

.my-cus-select .ant-select-selector {
  background-color: #2b2e83 !important;
  color: white;
}
.my-cus-select.ant-select-single.ant-select-open .ant-select-selection-item {
  color: white !important;
}
.my-cus-select span.ant-select-selection-item {
  line-height: 36px !important;
}

.my-cus-selects .ant-select-selector {
  background-color: #96c11f !important;
  color: white;
}
.my-cus-selects.ant-select-single.ant-select-open .ant-select-selection-item {
  color: white !important;
}

.my-cus-selects span.ant-select-selection-item {
  line-height: 36px !important;
}
