@import "style.scss";
@import "ant.scss";
@import "~@coreui/coreui/scss/coreui.scss";
@import "custom.scss";
@import "color.scss";
@import url("../fonts/kanit/stylesheet.css");
@import "./coreui.scss";
@import "animate.css";
@import "./mobile.scss";

* {
  font-family: "Kanit", sans-serif;
}
svg {
  vertical-align: baseline !important;
}

html {
  scroll-behavior: smooth;
}
::selection {
  background-color: $selected !important;
  color: #fff;
}

.text-center {
  text-align: "center" !important;
}

.text-indent-0 {
  text-indent: 0 !important;
}
