@import "color.scss";
.c-header {
  min-height: 70px !important;
  background: $bg_color;
  border-bottom: none !important;
  margin-left: 30px;
  padding-top: 40px;
}
.c-main {
  background-color: $bg_color;
  // margin-left: 45px;
}
.c-sidebar {
  color: white !important;
  background: $primary-color;
}
@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin: 10px;
    border-radius: 10px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper,
  html:not([dir="rtl"])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed
    ~ .c-wrapper {
    margin-left: 290px;
  }
}

.c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 10px;
}
// .c-sidebar-nav-dropdown {
//   padding: 0px 15px 1px 15px !important;
// }
// .c-sidebar-nav .c-sidebar-nav-item {
//   padding: 0px 15px 1px 15px !important;
// }

.c-sidebar-nav-dropdown-toggle:hover {
  background: $primary-color !important;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: $primary-color !important;
}

.c-sidebar-brand {
  background: rgba(255, 255, 255, 0) !important;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff !important;
  background: $selected !important;
  border-radius: 5px;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: $primary-color !important;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"])
  .c-sidebar-nav-dropdown-items
  .c-sidebar-nav-dropdown-toggle {
  background: rgba(255, 255, 255, 0.048);
  border-radius: 5px;
  padding-left: 40px;
}

.c-app {
  background-color: $bg_color !important;
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed {
  ul.c-sidebar-nav.h-100.ps {
    margin: 0 10px 0 10px;
  }
  a.c-sidebar-nav-link {
    //font-size: 18px;
  }
}

.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-minimized {
  li.c-sidebar-nav-item {
    a.c-sidebar-nav-link {
      border-radius: 5px;
      img.c-sidebar-nav-icon {
        margin-right: 15px;
      }
    }
  }

  li.c-sidebar-nav-dropdown {
    border-top-right-radius: 5px;
    ul.c-sidebar-nav-dropdown-items {
      background-color: $primary-color;
      border-bottom-right-radius: 5px;
    }
    a.c-sidebar-nav-dropdown-toggle:hover {
      border-top-right-radius: 5px;
    }
    img.c-sidebar-nav-icon {
      margin-right: 15px;
    }
  }
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background-color: $primary-color;
}
